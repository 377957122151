<template>
  <div>
    <v-card>
      <v-toolbar dense flat dark color="#133B7D">
        <v-toolbar-title>
          <span>Buscar por</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="formularioValido" lazy-validation>
          <v-row dense>
            <v-col v-if="error" cols="12">
              <v-alert type="error" dense>{{ error }}</v-alert>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="nombre"
                outlined
                dense
                label="Nombre"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="apellidoPaterno"
                outlined
                dense
                label="Apellido Paterno"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="apellidoMaterno"
                outlined
                dense
                label="Apellido Materno"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="rfc"
                outlined
                dense
                label="RFC"
                :rules="[$rules.rfcSinClave]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="telefono"
                outlined
                dense
                label="Teléfono"
                prepend-inner-icon="mdi-phone"
                counter="10"
                :rules="[$rules.numeric, longDigitValidation(10)]"
                :maxLength="10"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="email"
                outlined
                dense
                label="Correo electrónico"
                prepend-inner-icon="mdi-email"
                :rules="[$rules.email]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div>
          <v-btn
            color="primary"
            :disabled="!formularioValido"
            :loading="loading"
            @click="aplicarFiltros()"
          >
            <v-icon small class="mr-1">mdi-magnify</v-icon>
            <span>Aplicar filtros</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      telefono: "",
      email: "",
      rfc: "",
      formularioValido: true,
      error: "",
      longDigitValidation(number) {
        return (v) =>
          !v ||
          v.length == number ||
          "Debe proporcionar " + number + " digitos";
      },
    };
  },
  methods: {
    aplicarFiltros() {
      this.error = "";
      const filtros = {
        ...(this.nombre && { nombre: this.nombre }),
        ...(this.email && { correo: this.email }),
        ...(this.apellidoPaterno && { apellido_paterno: this.apellidoPaterno }),
        ...(this.apellidoMaterno && { apellido_materno: this.apellidoMaterno }),
        ...(this.telefono && { telefono: this.telefono }),
        ...(this.rfc && { rfc: this.rfc }),
      };
      if (Object.keys(filtros).length < 1) {
        this.error = "Debes ingresar al menos un filtro";
        this.formularioValido = false;
        return;
      } else this.$emit("filtrosModificados", filtros);
    },
  },
};
</script>
