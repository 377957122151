<template>
  <div>
    <v-row dense>
      <v-col cols="6">
        <FiltrosLeads
          @filtrosModificados="realizarBusqueda"
          :loading="loading"
        />
      </v-col>
      <v-col cols="6">
        <LeadsAgenda />
      </v-col>
      <v-col cols="12">
        <ResultadosLeads :loading="loading" :leads="leads" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import logoSegurify from "@/assets/logo_segurify.png";
import FiltrosLeads from "./FiltrosLeads.vue";
import ResultadosLeads from "./ResultadosLeads.vue";
import LeadsAgenda from "./LeadsAgenda.vue";

import { mainAxios } from "../../../../mainAxios";

export default {
  components: {
    FiltrosLeads,
    ResultadosLeads,
    LeadsAgenda,
  },
  data() {
    return {
      logoSegurify,
      loading: false,
      leads: [],
    };
  },
  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
  },
  methods: {
    realizarBusqueda(nuevosFiltros) {
      this.loading = true;
      this.leads = [];
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post("/v1/lead-segurify/list/filtros", nuevosFiltros, config)
        .then((response) => {
          this.loading = false;
          if (!response.data.length || response.data[0].error) return;
          response.data.forEach((lead) => {
            this.leads.push({
              id: lead.origenId,
              nombre: `${lead.nombre} ${lead.apellidoPaterno} ${lead.apellidoMaterno}`,
              medio: lead.medio,
              ramo: lead.ramo,
              canal: lead.canal,
              producto: lead.producto,
              telefono: lead.telefono,
              correo: lead.correo,
              uuid: lead.uuid,
              uuid_lead_segurify: lead.uuid,
              foo: "bar",
              lead:
                lead.nombre +
                " " +
                lead.apellidopaterno +
                " " +
                lead.apellidomaterno,
              servicio_embebido: lead.servicio_embebido,
              embedded: false,
            });
          });
        });
    },
  },
};
</script>
