var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","flat":"","color":"#133B7D"}},[_c('v-toolbar-title',[_c('span',[_vm._v("Resultados de búsqueda")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.leads,"headers":_vm.headers,"no-data-text":"Sin leads para mostrar","loading-text":"Buscando leads...","loading":_vm.loading,"footer-props":{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Elementos por página',
        }},scopedSlots:_vm._u([{key:"item.view",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.changeRoute(item)}}},[_vm._v(" mdi-eye ")])],1)]}},{key:"footer.page-text",fn:function(ref){
        var pageStart = ref.pageStart;
        var pageStop = ref.pageStop;
        var itemsLength = ref.itemsLength;
return [(itemsLength)?_c('div',[_vm._v(" Mostrando "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" leads ")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }